<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <div class="content_section" style="background-color: #052a71;margin-top: 160px">
            <div class="main">
                <div class="main_img" v-if="$store.state.country === i18nConst.KR">
                    <img src="../../assets/images/main/betstoa/main.png" alt="" style="width: 100%">
                </div>
                <div class="main_img" v-else>
                    <img src="../../assets/images/main/betstoa/main_en.jpg" alt="" style="width: 100%">
                </div>

                <div class="main_cont">
                    <div class="mc_left">
                        <div class="main_line">
                            <div class="line1">
                                <div style="height: 2px;background-color: #ff8901;width: 100%"></div>
                            </div>
                            <div style="color: #fff9ff;font-weight: bold;font-size: 14px">
                                BETSTOA
                            </div>
                            <div class="line1">
                                <div style="height: 2px;background-color: #ff8901;width: 100%"></div>
                            </div>
                        </div>
                        <div class="main_bnimgs">
                            <router-link tag="div" :to="{path: '/sports', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/main/betstoa/bn01.png" alt="">
                            </router-link>
                            <router-link tag="div" :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/main/betstoa/bn03.png" alt="">
                            </router-link>
                            <router-link tag="div" :to="{path: '/casino', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/main/betstoa/bn02.png" alt="">
                            </router-link>
                        </div>
                    </div>
                    <div class="mc_right">
                        <div class="main_line">
                            <div class="line1">
                                <div style="height: 1px;background-color: #ff8901;width: 100%"></div>
                            </div>
                            <div style="color: #fff9ff;font-weight: bold;font-size: 14px">
                                {{i18nConst.MENU['NOTICE'][$store.state.country]}}
                            </div>
                            <div class="line1">
                                <div style="height: 1px;background-color: #ff8901;width: 100%"></div>
                            </div>
                        </div>
                        <div class="main_notice">
                            <router-link v-for="item in noticeList" tag="div"  :to="{path: '/notice', query: {t: new Date().getTime(),id:item.id}}">
                                <i class="fa fa-dot-circle-o"></i>&nbsp;
                                <span v-if="$store.state.country === i18nConst.KR">{{item.title}}</span>
                                <span v-if="$store.state.country === i18nConst.EN">{{item.titleEn}}</span>
                                <span v-if="$store.state.country === i18nConst.JP">{{item.titleJp}}</span>
                                <span v-if="$store.state.country === i18nConst.Spa">{{item.titleSpa}}</span>
                                <span v-if="$store.state.country === i18nConst.Fra">{{item.titleFra}}</span>
                            </router-link>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <right-bar-comp style="display: none">
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import {WEBSOCKET_URL} from "../../common/const";
    import {getNoticeList} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyGames} from "../../network/sportsRequest";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "Main",
        mixins:[postionMixin],
        components: {
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList:[],
                noticePageSize:6,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position:"메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer:[],
                base:[],
                basket:[],
                hokey:[],
                esports:[],
                vol:[],
                i18nConst:i18nConst,
            }
        },
        methods: {

        },
        created() {
            // getRecentlyGames().then(res=>{
            //     if (res.data.success) {
            //         let recentlryGames = res.data.data
            //         this.soccer = recentlryGames.soccer
            //         this.basket = recentlryGames.bascket
            //         this.base = recentlryGames.base
            //         this.vol = recentlryGames.vol
            //         this.hokey = recentlryGames.hockey
            //         this.esports = recentlryGames.esports
            //     }
            // })
            getNoticeList(this.noticePageSize).then(res=>{
                this.noticeList = res.data.data
            })
        }
    }
</script>
<style scoped>
    @import url("../../assets/css/main.css");
    .main_cont{
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
    }
    .mc_left{
        width: 73%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }
    .mc_right{
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
    }
    .main_cont .main_line{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        height: 15px;
        margin-top: 10px;
    }
    .line1{
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mc_left .main_bnimgs{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .mc_left .main_bnimgs div{
        width: 33.2%;
        cursor: pointer;
    }
    .mc_left .main_bnimgs img{
        width: 100%;
    }
    .mc_right .main_notice{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .mc_right .main_notice div{
        width: 100%;
        color: #fff9ff;
        padding: 8px 0;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
    .mc_right .main_notice i{
        color: #ff8a00;
    }
    @media screen and (max-width: 1024px) {
        .mc_left{
            width: 100% !important;

        }
        .mc_right{
            width: 100% !important;
            margin-top: 10px;
        }
        .mc_right .main_notice{

            padding: 5px;
        }
    }
</style>
